import axios from 'axios'

class AccountFactory {

  async get () {
    try {
      let url = '/acc/accounts'
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async search (keyword:string) {
    try {
      let url = '/acc/accounts/search'
      var params = { keyword: keyword }
      var response = await axios.get(url, { params: params })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async create (data: any) {
    try {
      let url = '/acc/accounts'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async update (id: any, data: any) {
    try {
      let url = `/acc/accounts/${id}`
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

export let accountFactory = new AccountFactory()
